import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-page',
    templateUrl: './no-page.component.html',
    styleUrls: ['./no-page.component.scss']
})
export class NoPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
