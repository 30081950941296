import { Component, OnInit } from '@angular/core';
import { CallCardService } from '../call-card/call-card.service';
import { ContactCardService } from './contact-card.service';

@Component({
    selector: 'app-contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss']
})

export class ContactCardComponent implements OnInit {

    constructor(
        public contactCardService: ContactCardService
    ) { }

    ngOnInit(): void { }
}
