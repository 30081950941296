import { Component, OnInit } from '@angular/core'
import anime from 'animejs/lib/anime.es.js'
import { CallCardService } from '../call-card/call-card.service'
import { ContactCardService } from '../contact-card/contact-card.service'
declare var $:any

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

    constructor(
        public callCardService: CallCardService,
        public contactCardService: ContactCardService
    ) { }

    ngOnInit(): void {
    }
}
