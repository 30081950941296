<div class="is-section m-0">
    <div class="is-holder">
        <div class="is-left-section" data-aos="fade-zoom-in" data-aos-duration="1200" data-aos-once="true">
            <!-- <div class="logo-pill">
                <h1 class="logo-left">Medi</h1>
                <h1 class="logo-right">Services</h1>
            </div> -->
            <div class="is-logo">
                <img data-aos="flip-left" data-aos-duration="1800" src="/assets/logo.png" />
                <h1>MEDISERVICES</h1>
            </div>
        </div>
        <div class="is-right-section" data-aos="fade-zoom-in" data-aos-duration="1200" data-aos-once="true">
            <p class="is-subtext">World class medical consultancy services accross Rajasthan</p>
        </div>
    </div>
    <div class="btm-overlay"></div>
</div>