import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { NoPageComponent } from './pages/no-page/no-page.component';
import { PartnersComponent } from './pages/partners/partners.component';

const routes: Routes = [
    { path: 'Partners', component: PartnersComponent },
    { path: 'Home', component: HomeComponent },
    { path: 'About', component: AboutComponent },
    { path: '',   redirectTo: '/Home', pathMatch: 'full' },
    { path: '**', component: NoPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }