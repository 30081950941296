<app-header activeTab="about"></app-header>
<div class="is-section section-1">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="heading">ABOUT MEDISERVICES</h3>
                <div class="section1content">
                    <div class="row">
                        <div class="col-12 col-md-8" style="display: flex; justify-content: center; align-items: center;">
                            <p style="font-size: 1.4em;">
                                Mediservices’ consulting is about helping it’s clients realize business value through it’s
                                infrastructure, consulting and communication services to build tomorrow’s healthcare
                                facilities. It’s goal is to enable clients to develop strategies, designs and buildings to
                                meet the ever growing and changing needs of patients the world over.
                            </p>
                        </div>
                        <div class="col-12 col-md-4 bars-section">
                            <div class="row">
                                <div class="col-12">
                                    <h6>PROJECT SERVICES</h6>
                                    <div class="progress">
                                        <div class="progress-bar" style="width: 90%;background-color: #ffcc33;"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <h6>CONSULTANCY SERVICES</h6>
                                    <div class="progress">
                                        <div class="progress-bar" style="width: 95%;background-color: #2d7da4;"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <h6>DESIGN SERVICES</h6>
                                    <div class="progress">
                                        <div class="progress-bar" style="width: 88%;background-color: #6aa42f;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="is-section section-2">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="heading">OUR MANAGING DIRECTOR</h3>
                <div class="row" style="margin-top: 3em;">
                    <div class="col-12 col-md-6" style="display: flex; justify-content: center; align-items: flex-start;">
                        <div class="is-image"></div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="section2content">
                            <div class="row">
                                <div class="col-12">
                                    <p class="card-text m-0" style="font-size:1.4em; color: #777;">
                                        <strong>Mr. VISHESH VYAS</strong>
                                    </p>
                                    <p class="card-text mt-1" style="font-size:1.4em; color:#999;">
                                        <i>(MBA, Hospital Management, Bsc MLT)</i><br>
                                    </p>
                                    <p class="card-text" style="font-size:1.4em; line-height: 2em;">
                                        <i>Vice President</i>, All Rajasthan Private Hospital Association<br>
                                        <i>Director</i>, Pranjal Hospital, Palsana<br>
                                        <i>Seceratory</i>, Shekhawati Private Hospital Association<br>
                                    </p>
                                    <p class="card-text" style="font-size:1.4em;">
                                        In The ERA of E- Health
                                        There is a tremendous growth in healthcare spending all over the world. I strongly
                                        believe
                                        that the healthcare market hold a great potential with several segments of
                                        healthcare spending still remaining untapped. under the very same belife
                                        mediconcept healthcare service providing that untapped service solutions to the
                                        healthcare service providers and individuals will increase opportunities for the
                                        industry players for expanding their operations and commercial success.It’s goal
                                        is to enable clients to develop strategies, designs and buildings to meet the
                                        ever growing and changing needs of patients the world over.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>