<div class="is-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 is-head">
                <p class="is-heading mb-0">WHY MEDISERVICES?</p>
                <p class="is-subheading">EXCEPTIONAL SERVICES ACCROSS ALL SECTORS</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-container">
                    <div class="card-flex">
                        <div class="card" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <img class="card-img-top" src="/assets/img/1.png" alt="Image">
                            <div class="card-body">
                                <h5 class="card-title">Assignment Management</h5>
                                <p class="card-text">Assignments are very time sensitive and our agents are trained for the same</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <img class="card-img-top" src="/assets/img/2.png" alt="Image">
                            <div class="card-body">
                                <h5 class="card-title">Cost Effectiveness</h5>
                                <p class="card-text">We understand your needs and collaborate our resources accordingly</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <img class="card-img-top" src="/assets/img/3.png" alt="Image">
                            <div class="card-body">
                                <h5 class="card-title">Responsive Support</h5>
                                <p class="card-text">Our quick and responsive support will help you focus on customer satisfaction</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>