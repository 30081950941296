import { Injectable } from '@angular/core';
declare var $: any

@Injectable({
    providedIn: 'root'
})

export class ContactCardService {

    visible: boolean = false

    constructor() { }

    openDialog() {
        this.visible = true
        setTimeout(() => {
            $('.contact-card').fadeTo(300, 1, () => {
            })
            $('.contact-card .card').animate({ 'bottom': '0' }, 400)
            document.body.classList.toggle('noscroll', true)
        }, 100)
    }

    closeDialog(evt) {
        if(evt) {
            let path: Element[] = evt.composedPath()
            let index = path.indexOf($('.contact-card .card')[0])
            if(index !== -1) {
                evt.preventDefault()
                return
            }
        }
        $('.contact-card .card').animate({ 'bottom': '-100vh' }, 400, () => {
        })
        $('.contact-card').fadeTo(400, 0, () => {
            this.visible = false
        })
        document.body.classList.toggle('noscroll', false)
    }
}
