<div id="contact-card" class="contact-card" *ngIf="contactCardService.visible" (click)="contactCardService.closeDialog($event)">
    <div class="card card-body">
        <div class="form-group">
            <label>Name</label>
            <input type="text" class="form-control" placeholder="Your Name" required />
        </div>
        <div class="form-group">
            <label>Email<span class="text-success">*</span></label>
            <input type="text" class="form-control" placeholder="Email Address" />
        </div>
        <div class="form-group">
            <label>Contact Number</label>
            <input type="text" class="form-control" placeholder="Contact Information" />
        </div>
        <div class="form-group">
            <label>Enter Your Message<span class="text-success">*</span></label>
            <textarea class="form-control" rows="4" placeholder="Message for our Team" required></textarea>
        </div>
        <div class="form-group text-right mb-0">
            <button class="btn btn-info mr-2">
                <i class="fa fa-send mr-1"></i>
                Send
            </button>
            <div
                (click)="contactCardService.closeDialog()"
                class="btn btn-secondary close-btn">
                <i class="fa fa-angle-double-down"></i>
                CLOSE
            </div>
        </div>
    </div>
</div>