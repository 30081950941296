import { Component } from '@angular/core';
import * as AOS from 'aos'
declare var $: any

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

    appLoading: boolean = false

    ngOnInit() {
        AOS.init()
        document.addEventListener('scroll', (e) => {
            if(window.scrollY > 300) {
                $('.float-right').css({'opacity': '1'})
            } else {
                $('.float-right').css({'opacity': '0'})
            }
        })
    }

    gotoTop() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    }

    ngOnDestroy(): void {
        document.removeEventListener('scroll', (e) => {})
    }
}
