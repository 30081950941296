import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-d',
    templateUrl: './section-d.component.html',
    styleUrls: ['./section-d.component.scss']
})

export class SectionDComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
