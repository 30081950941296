<div class="is-section">
    <div class="container-fluid">
        <div class="is-container">
            <div class="card-left-container">
                <img class="img-thumbnail p-0" src="/assets/img/5.png" alt="Image">
            </div>
            <div class="card-right-container">
                <div class="card card-body p-5" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                    <div class="is-header mb-4" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                        <i class="fa fa-bar-chart"></i>
                        <h5 class="card-title">WE DELIVER BUSINESS VALUES</h5>
                    </div>
                    <p class="card-text" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                        Since 2011, Mediservices has set new benchmarks in hospital planning and
                        design with regard to quality, expertise and efficiency. We have partnered with some of
                        Rajasthan’s prestigious hospitals to create these benchmarks.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>