<div id="call-card" class="call-card" *ngIf="callCardService.visible" (click)="callCardService.closePhoneDialog($event)">
    <div class="card card-body">
        <div class="list-group">
            <a href="tel:+91-992-980-6789" class="list-group-item list-group-item-action list-group-item-success">
                <i class="fa fa-phone mr-1"></i> 
                CALL <b>+91-9929806789</b>
            </a>
        </div>
        <div class="row">
            <div class="col-12 text-right pt-3">
                <div
                    (click)="callCardService.closePhoneDialog()"
                    class="btn btn-secondary close-btn" >
                    <i class="fa fa-angle-double-down"></i>
                    CLOSE
                </div>
            </div>
        </div>
    </div>
</div>