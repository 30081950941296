import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-e',
  templateUrl: './section-e.component.html',
  styleUrls: ['./section-e.component.scss']
})
export class SectionEComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
