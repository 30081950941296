<nav class="is-navbar navbar navbar-expand-lg fixed-top navbar-light">
    <a class="navbar-brand" href="#">MEDISERVICES</a>
    <button class="navbar-toggler" type="button" 
        (click)="buttonClicked()"
        data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item px-2" [ngClass]="{'active': activeTab==='home'}">
                <a class="nav-link" href="/">
                    HOME
                    <span class="sr-only" *ngIf="activeTab==='home'">(current)</span>
                </a>
            </li>
            <li class="nav-item px-2" [ngClass]="{'active': activeTab==='partners'}">
                <a class="nav-link" href="/Partners">
                    PARTNERS
                    <span class="sr-only" *ngIf="activeTab==='partners'">(current)</span>
                </a>
            </li>
            <li class="nav-item px-2" [ngClass]="{'active': activeTab==='about'}">
                <a class="nav-link" href="/About">
                    ABOUT
                    <span class="sr-only" *ngIf="activeTab==='about'">(current)</span>
                </a>
            </li>
        </ul>
    </div>
</nav>