<div class="is-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p class="is-heading">CARDS DISPLAY</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-container">
                    <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-delay="0" data-aos-once="true">
                        <i class="fa fa-user-o"></i>
                        <h5 class="card-title">Assignment Management</h5>
                        <p class="card-text">Assignments are very time sensitive and our agents are trained for the same</p>
                    </div>
                    <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-delay="200" data-aos-once="true">
                        <i class="fa fa-user-o"></i>
                        <h5 class="card-title">Cost Effectiveness</h5>
                        <p class="card-text">Our network of managers & resources will help you find cost effective solution in every situation</p>
                    </div>
                    <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-delay="400" data-aos-once="true">
                        <i class="fa fa-user-o"></i>
                        <h5 class="card-title">Responsive Support</h5>
                        <p class="card-text">Our quick and responsive support will help you focus on customer satisfaction</p>
                    </div>
                    <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-delay="600" data-aos-once="true">
                        <i class="fa fa-user-o"></i>
                        <h5 class="card-title">Responsive Support</h5>
                        <p class="card-text">Our quick and responsive support will help you focus on customer satisfaction</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>