import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
declare var $: any

@Component({
    selector: 'app-section-b',
    templateUrl: './section-b.component.html',
    styleUrls: ['./section-b.component.scss']
})

export class SectionBComponent implements OnInit {

    @ViewChild('container') private container: ElementRef<HTMLDivElement>
    num1: number = 32
    num2: number = 184
    num3: number = 12
    num4: number = 108

    constructor() { }

    ngOnInit(): void {
        // let list = document.addEventListener('scroll', (e) => {
        //     const rect = this.container.nativeElement.getBoundingClientRect()
        //     const topShown = rect.top >= 0
        //     const bottomShown = rect.bottom <= window.innerHeight
        //     let isShowing: boolean = topShown && bottomShown
        //     if(isShowing) {
        //         document.removeEventListener('scroll', () => { })
        //         this.runNumber(1)
        //         this.runNumber(2)
        //         this.runNumber(3)
        //         this.runNumber(4)
        //     }
        // })

    }

    ngAfterViewInit() {
        let animate = () => {
            $('.is-btm-anim').css({'left':'90%'})
            setTimeout(() => {
                $('.is-btm-anim').css({'left':'-20%'})
            }, 2000)
        }
        setTimeout(() => animate(), 300)
        setInterval(() => animate(), 8000)
    }

    runNumber(numType:number, endValue: number = 234) {
        let duration: number = 1400
        let steps: number = 10
        const stepCount = Math.abs(duration / steps)
        const valueIncrement = (endValue - 0) / stepCount
        const sinValueIncrement = Math.PI / stepCount
        let currentValue = 0
        let currentSinValue = 0
        let step = () => {
            currentSinValue += sinValueIncrement;
            currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2
            let value = Math.abs(Math.floor(currentValue))
            if((endValue - value) < 5) value = endValue
            if(numType === 1) this.num1 = value
            if(numType === 2) this.num2 = value
            if(numType === 3) this.num3 = value
            if(numType === 4) this.num4 = value
            if (currentSinValue < Math.PI) {
                window.requestAnimationFrame(step)
            }
        }
        step()
    }
}
