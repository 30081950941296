import { Injectable } from '@angular/core';
declare var $: any

@Injectable({
    providedIn: 'root'
})

export class CallCardService {

    visible: boolean = false

    constructor() { }

    openPhoneDialog() {
        this.visible = true
        setTimeout(() => {
            $('.call-card').fadeTo(300, 1, () => {
            })
            setTimeout(() => {
                $('.call-card .card').animate({'bottom': '-10px'}, 400, () => {
                })
            }, 100)
        }, 100)
    }

    closePhoneDialog(evt) {
        if(evt) {
            let path: Element[] = evt.composedPath()
            let index = path.indexOf($('.call-card .card')[0])
            if(index !== -1) {
                evt.preventDefault()
                return
            }
        }
        $('.call-card .card').animate({ 'bottom': '-100vh' }, 400, () => {
        })
        $('.call-card').fadeTo(400, 0, () => {
            this.visible = false
        })
    }
}
