<div class="is-section">
    <!--FAQ SECTION-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 is-head">
                <p class="is-heading">FREQUESTLY ASKED QUESTIONS (FAQs)</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="is-left-col">
                    <div class="card" *ngFor="let faqQues of faqQuestionsLeft" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-delay="0" data-aos-once="true">
                        <div id="faq-{{faqQues.id}}-heading" class="card-header faq-heading" (click)="toggleCollapse(faqQues.id)">
                            <p class="mb-0">
                                {{faqQues.question}}
                            </p>
                            <i id="faq-{{faqQues.id}}-icon" class="fa fa-caret-down"></i>
                        </div>
                        <div id="faq-{{faqQues.id}}-content" class="faq-content">
                            <div class="card-body">
                                {{faqQues.answer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="is-right-col">
                    <div class="card" *ngFor="let faqQues of faqQuestionsRight" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-delay="0" data-aos-once="true">
                        <div id="faq-{{faqQues.id}}-heading" class="card-header faq-heading" (click)="toggleCollapse(faqQues.id)">
                            <p class="mb-0">
                                {{faqQues.question}}
                            </p>
                            <i id="faq-{{faqQues.id}}-icon" class="fa fa-caret-down"></i>
                        </div>
                        <div id="faq-{{faqQues.id}}-content" class="faq-content">
                            <div class="card-body">
                                {{faqQues.answer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>