import { Component, OnInit } from '@angular/core'
import anime from 'animejs/lib/anime.es.js'
declare var $:any

@Component({
    selector: 'app-section-g',
    templateUrl: './section-g.component.html',
    styleUrls: ['./section-g.component.scss']
})
export class SectionGComponent implements OnInit {

    faqQuestionsLeft: {id: number, question: string, answer: string}[] = [{
        id: 1,
        question: 'Can I get a Quotation on my Project?',
        answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.'
    },{
        id: 2,
        question: 'Can I pariatur cliche reprehenderit on my Project?',
        answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.'
    },{
        id: 3,
        question: 'Can I enim eiusmod high life on my Project?',
        answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.'
    }]

    faqQuestionsRight: {id: number, question: string, answer: string}[] = [{
        id: 4,
        question: 'Can I reprehenderit a Quotation on my Project?',
        answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.'
    },{
        id: 5,
        question: 'Can accusamus terry get a Quotation on my Project?',
        answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.'
    },{
        id: 6,
        question: 'Can I cliche on my Project?',
        answer: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.'
    }]

    openQuestions: {[questionId: number]: boolean} = {}

    constructor() { }

    ngOnInit(): void {
        let a = $('.section-7 .accordion')
        let b = a.on('hidden.bs.collapse', function () {
            console.log(`Hideen`)
        })
        $('.section-7 .accordion').on('shown.bs.collapse', function () {
            console.log(`SHOWWN`)
        })
    }

    toggleCollapse(quesId: number) {
        this.openQuestions[quesId] = !Boolean(this.openQuestions[quesId])
        if (this.openQuestions[quesId]) {
            anime({
                targets: `#faq-${quesId}-content`,
                maxHeight: '300px',
                easing: 'easeInOutQuad',
                duration: 600
            })
            anime({
                targets: `#faq-${quesId}-icon`,
                rotate: '180deg',
                easing: 'easeInOutQuad',
                duration: 600
            })
        } else {
            anime({
                targets: `#faq-${quesId}-content`,
                maxHeight: '0px',
                easing: 'easeInOutQuad',
                duration: 600
            })
            anime({
                targets: `#faq-${quesId}-icon`,
                rotate: '0deg',
                easing: 'easeInOutQuad',
                duration: 600
            })
        }
    }

}
