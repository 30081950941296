import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomeComponent } from './pages/home/home.component'
import { PartnersComponent } from './pages/partners/partners.component'
import { AboutComponent } from './pages/about/about.component'
import { SectionAComponent } from './pages/home/section-a/section-a.component'
import { SectionBComponent } from './pages/home/section-b/section-b.component'
import { SectionCComponent } from './pages/home/section-c/section-c.component'
import { FooterComponent } from './pages/footer/footer.component'
import { HeaderComponent } from './pages/header/header.component'
import { ContactUsPopupComponent } from './pages/contact-us-popup/contact-us-popup.component'
import { SectionDComponent } from './pages/home/section-d/section-d.component'
import { SectionEComponent } from './pages/home/section-e/section-e.component'
import { SectionFComponent } from './pages/home/section-f/section-f.component'
import { SectionGComponent } from './pages/home/section-g/section-g.component'
import { NoPageComponent } from './pages/no-page/no-page.component';
import { CallCardComponent } from './pages/call-card/call-card.component';
import { ContactCardComponent } from './pages/contact-card/contact-card.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PartnersComponent,
    AboutComponent,
    SectionAComponent,
    SectionBComponent,
    SectionCComponent,
    FooterComponent,
    HeaderComponent,
    ContactUsPopupComponent,
    SectionDComponent,
    SectionEComponent,
    SectionFComponent,
    SectionGComponent,
    NoPageComponent,
    CallCardComponent,
    ContactCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
