<div class="is-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 is-head">
                <p class="is-heading mb-0">OUR SERVICES</p>
                <p class="is-subheading">WE BELIEVE IN LIFE LONG RELATIONSHIPS</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-container">
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <!-- <i class="fa fa-user-o"></i> -->
                            <h5 class="card-title">CONSULTANCY SERVICES</h5>
                            <p class="card-text">
                                Ideate, strategize and conceptualize the healthcare facility of your dreams
                            </p>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <!-- <i class="fa fa-user-o"></i> -->
                            <h5 class="card-title">DESIGN SERVICES</h5>
                            <p class="card-text">
                                Whether a new or existing facility, the approach to design is will keep your vision and values centre stage
                            </p>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <!-- <i class="fa fa-user-o"></i> -->
                            <h5 class="card-title">PROJECT SERVICES</h5>
                            <p class="card-text">
                                Our experts will ensure the quality, cost and time triad is maintained at all times
                            </p>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <!-- <i class="fa fa-user-o"></i> -->
                            <h5 class="card-title">COMMUNICATION SERVICES</h5>
                            <p class="card-text">
                                Our Media Solutions will help you build you brand and reinforce your business strategies
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>