import { Component, OnInit } from '@angular/core';
import { CallCardService } from './call-card.service';
declare var $: any

@Component({
    selector: 'app-call-card',
    templateUrl: './call-card.component.html',
    styleUrls: ['./call-card.component.scss']
})

export class CallCardComponent implements OnInit {

    constructor(
        public callCardService: CallCardService
    ) { }

    ngOnInit(): void {
    }
}
