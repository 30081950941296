import { Component, OnInit } from '@angular/core'
declare var $: any

@Component({
    selector: 'app-section-a',
    templateUrl: './section-a.component.html',
    styleUrls: ['./section-a.component.scss']
})
export class SectionAComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    ngAfterViewInit() {
    }
}