<app-header activeTab="home"></app-header>
<app-section-a></app-section-a>
<app-section-b></app-section-b>
<app-section-c></app-section-c>
<app-section-e></app-section-e>
<app-section-f></app-section-f>
<app-section-g></app-section-g>
<app-footer></app-footer>
<div class="float-left">
    <button 
        (click)="callCardService.openPhoneDialog()"
        class="btn btn-success" 
        data-aos="fade-up" data-aos-offset="0" data-aos-duration="1800" >
        <i class="fa fa-phone"></i>
    </button>
    <button 
        (click)="contactCardService.openDialog()"
        class="btn btn-success" 
        data-aos="fade-up" data-aos-offset="0" data-aos-duration="1800" >
        <i class="fa fa-envelope"></i>
    </button>
</div>
<app-call-card></app-call-card>
<app-contact-card></app-contact-card>