import { Component, Input, OnInit } from '@angular/core'
declare var $: any

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

    @Input() activeTab: 'home' | 'partners' | 'about'

    navbarOffset: number = 2000

    constructor() { }

    ngOnInit(): void {
        let headerVisibleAt: number = 10
        document.addEventListener('scroll', (e) => {
            if(window.scrollY > headerVisibleAt) {
                $('.is-navbar').css({'background': '#fff'})
            } else {
                $('.is-navbar').css({'background': 'transparent'})
            }
        })
    }

    buttonClicked() {
        $('.is-navbar').css({'background': '#fff'})
    }

    ngOnDestroy(): void {
        document.removeEventListener('scroll', (e) => {})
    }
}
