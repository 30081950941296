import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss']
})

export class PartnersComponent implements OnInit {

    /**
     * null for all locations
     */
    selectedLocation: null | 'Kota' | 'Jaipur' | 'Sikar' = null

    locations = ['Kota', 'Sikar', 'Jaipur']

    partners2 = []
    partners = [{
        id: 1,
        name : 'Vedanta Hospital',
        location : 'Sikar'
    }, {
        id: 2,
        name : 'Bindal Hospital',
        location : 'Sikar'
    }, {
        id: 3,
        name : 'Shriram Hospital',
        location : 'Sikar'
    }, {
        id: 4,
        name : 'Manu Hospital',
        location : 'Sikar'
    }, {
        id: 5,
        name : 'Amar Hospital',
        location : 'Sikar'
    }, {
        id: 6,
        name : 'Vinayak Hospital',
        location : 'Sikar'
    }, {
        id: 7,
        name : 'K.D. Sharma Hospital',
        location : 'Sikar'
    }, {
        id: 8,
        name : 'Pranjal Hospital',
        location : 'Sikar'
    }, {
        id: 9,
        name : 'Balaji Hospital',
        location : 'Sikar'
    }, {
        id: 10,
        name : 'Bhagwati Hospital',
        location : 'Sikar'
    }, {
        id: 11,
        name : 'Janata Hospital',
        location : 'Sikar'
    }, {
        id: 12,
        name : 'Krishna Hospital',
        location : 'Sikar'
    }, {
        id: 13,
        name : 'Laddah Hospital',
        location : 'Sikar'
    }, {
        id: 14,
        name : 'Lakecity Hospital',
        location : 'Sikar'
    }, {
        id: 15,
        name : 'Madhav Hospital',
        location : 'Sikar'
    }, {
        id: 16,
        name : 'Hospital',
        location : 'Sikar'
    }, {
        id: 17,
        name : 'Nishu Hospital',
        location : 'Sikar'
    }, {
        id: 18,
        name : 'Nero Spine Hospital',
        location : 'Sikar'
    }, {
        id: 19,
        name : 'Patel Hospital',
        location : 'Sikar'
    }, {
        id: 20,
        name : 'Raj Hospital',
        location : 'Sikar'
    }, {
        id: 21,
        name : 'Riddhi Siddhi Eye Care',
        location : 'Sikar'
    }, {
        id: 22,
        name : 'Sanjeevani Hospital',
        location : 'Sikar'
    }, {
        id: 23,
        name : 'Shekhavati Hospital',
        location : 'Sikar'
    }, {
        id: 24,
        name : 'Shiv Hospital',
        location : 'Sikar'
    }, {
        id: 25,
        name : 'Usha Smile Care',
        location : 'Sikar'
    }, {
        id: 26,
        name : 'Vatsalya Hospital',
        location : 'Sikar'
    }, {
        id: 27,
        name : 'Bachpan Child Hospital',
        location : 'Sikar'
    }, {
        id: 28,
        name : 'Gurukripa Hospital',
        location : 'Sikar'
    }]

    constructor() { }

    ngOnInit(): void { }
}
