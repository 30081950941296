<app-header activeTab="partners"></app-header>
<div class="is-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="is-pills-container">
                    <nav class="nav nav-pills d-flex justify-content-center">
                        <a class="text-sm-center nav-link active" 
                            [ngClass]="{'active bg-success':(selectedLocation===null), 'text-success':(selectedLocation!==null)}"
                            (click)="selectedLocation = null">All (24)</a>
                        <a *ngFor="let location of locations" 
                            class="text-sm-center nav-link" 
                            [ngClass]="{'active bg-success':(selectedLocation===location), 'text-success':(selectedLocation!==location)}"
                            (click)="selectedLocation = location">{{location}}</a>
                    </nav>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-container">
                    <div class="card-flex" *ngFor="let partner of partners">
                        <div class="card">
                            <img class="card-img-top" src="/assets/clients/{{partner.id}}.png" alt="Image">
                            <div class="card-body">
                                <h5 class="card-title">{{partner.name}}</h5>
                                <p class="card-text">Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>