<div class="is-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card-container" #container>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <div class="row">
                                <div class="col-12">
                                    <div class="is-card-flex">
                                        <div class="is-card-left">
                                            <i class="fa fa-users"></i>
                                        </div>
                                        <div class="is-card-right">
                                            <p class="is-card-value">{{num1}}</p>
                                            <p class="is-card-text">PARTNERS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="is-btm-anim"></div>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <div class="row">
                                <div class="col-12 is-card-flex">
                                    <div class="is-card-left">
                                        <i class="fa fa-handshake-o"></i>
                                    </div>
                                    <div class="is-card-right">
                                        <p class="is-card-value">{{num2}}</p>
                                        <p class="is-card-text">ASSIGNMENTS</p>
                                    </div>
                                </div>
                            </div>
                            <div class="is-btm-anim"></div>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <div class="row">
                                <div class="col-12 is-card-flex">
                                    <div class="is-card-left">
                                        <i class="fa fa-pie-chart"></i>
                                    </div>
                                    <div class="is-card-right">
                                        <p class="is-card-value">{{num3}}</p>
                                        <p class="is-card-text">ASSOCIATES</p>
                                    </div>
                                </div>
                            </div>
                            <div class="is-btm-anim"></div>
                        </div>
                    </div>
                    <div class="card-flex">
                        <div class="card card-body" data-aos="fade-zoom-in" data-aos-duration="600" data-aos-once="true">
                            <div class="row">
                                <div class="col-12 is-card-flex">
                                    <div class="is-card-left">
                                        <i class="fa fa-bar-chart"></i>
                                    </div>
                                    <div class="is-card-right">
                                        <p class="is-card-value">{{num4}}</p>
                                        <p class="is-card-text">PROJECTS</p>
                                    </div>
                                </div>
                            </div>
                            <div class="is-btm-anim"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>